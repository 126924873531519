<template>
  <div class="auth-wrapper w-100 p-3 mx-auto h-100 d-flex justify-content-center align-content-center align-items-center bg-white">
    <b-row style="display: block">
      <div class="d-inline-block d-flex justify-content-center m-3 align-content-center align-items-center">
        <img
          style="width: 90px"
          src="@/assets/images/logo/OTP.png"
        >
      </div>
      <!-- form -->
      <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
        <p class="font-weight-bold heading text-primary">
          OTP
        </p>
      </div>
      <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
        <h4 style="font-size: 12px">
          Enter the code send on your phone number
        </h4>
      </div>
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              :num-inputs="6"
              separator=" "
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
          </div>
          <div
            class="form-group block"
          >
            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="verifyOtp"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <div class="d-inline-block d-flex justify-content-center font-weight-bolder align-content-center align-items-center">
        <b-link :to="{name:'phone-verification'}">
          <span>Resend</span>
        </b-link>
      </div>
      <!-- modal -->
      <b-modal
        v-model="showModal"
        centered
        hide-footer
        hide-header
        :show-modal="false"
      >

        <div class="d-flex justify-content-center align-content-center align-items-center bg-white">
          <b-row style="display: block">

            <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
              <img
                style="width: 80px"
                src="@/assets/images/simiicons/Success.svg"
              >
            </div>
            <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center m-2">
              <p class="font-weight-bolder heading text-primary">
                Great
              </p>
            </div>
            <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
              <h4 style="font-size: 14px">
                Your account has been successfully created
              </h4>
            </div>
            <div
              class="form-group block"
            >
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="!active"
                @click="succesSignup"
              >
                <div
                  v-if="spinner"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                  <span class="sr-only">Loading...</span>
                </div>
                Continue
              </b-button>
            </div>
          </b-row>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BButton, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Vue from 'vue'
import OtpInput from '@bachdgvn/vue-otp-input'
import store from '@/store'
import { showToast } from '@/common/global/functions'

Vue.component('VOtpInput', OtpInput)
export default {
  components: {
    BForm,
    BLink,
    BButton,
    BRow,
    BSpinner,
    // validations
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      codevalue: '',
      active: true,
      spinner: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      showModal: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleOnComplete(value) {
      this.codevalue = value
      return new Promise(() => {
        this.$axios
          .post('user/verify-token', {
            code: value,
          })
          .then(resp => {
            const { data } = resp.data
            this.$store.commit('user/SET_IS_OTP_SEND', false)
            this.$store.commit('user/SET_USER', data)
            this.showModal = true
          }).catch(() => {
            showToast('OTP Verification', 'Error in code verification!', 'danger', 4000)
            // this.$swal('Error in code verification!')
          })
      })
    },
    handleOnChange() {
    },
    verifyOtp() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          this.$axios
            .post('user/verify-token', {
              code: this.codevalue,
            })
            .then(resp => {
              const { data } = resp.data
              this.spinner = false
              this.active = true
              this.$store.commit('user/SET_IS_OTP_SEND', false)
              this.$store.commit('user/SET_USER', data)
              this.showModal = true
            }).catch(() => {
              showToast('OTP Verification', 'Error in code verification!', 'danger', 4000)
              // this.$swal('Error in code verification!')
            })
        }
      })
    },
    succesSignup() {
      this.$swal('Account Verified Successfully')
      this.$router.push('/complete-profile')
    },
  },
}
/* eslint-disable global-require */
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .otp-input {
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: 10px 10px;
    font-size: 32px;
    border-radius: 10px;
    border: 1px solid #e0dee1;
    background-color: #ffffff;
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .wrapper {
    .otp-input:not(:last-child) {
        margin-right: 8px;
    }
}

    .otp-input:focus{
        margin-right: 8px;
    }

@media screen and (max-width:767px) {
    .otp-input {
        width: 50px;
        font-size: 24px;
        height: 40px;
    }
}
@media screen and (max-width:420px) {
    .otp-input {
        width: 40px;
        font-size: 18px;
        height: 30px;
    }
}
    .heading{
    font-size: 25px;
  }
</style>
